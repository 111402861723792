/*
 * @Author: 艾斯
 * @Date: 2020-02-20 22:45:32
 * @LastEditTime: 2020-03-17 14:55:39
 * @LastEditors: Please set LastEditors
 * @Description: 常见函数
 * @FilePath: /vue/yunxue/src/common/index.js
 */
import baseURL from '@/api/http.js'
import storage from 'good-storage'

// 本地存储
const handleCache = (key, val = "") => {
  let nsTime = new Date().getTime()
  if (val) {
    let data = {
      nsTime: nsTime,
      data: val
    }
    storage.set(key, data)
  } else {
    let str = undefined
    if (storage.has(key)) {
      if (storage.get(key).nsTime) {
        let startTime = storage.get(key).nsTime,
          timediff = parseInt((nsTime - startTime) / 1000)
        // 如果缓存大于2天
        if (timediff > 60 * 60 * 24 * 2) {
          storage.remove(key)
        } else {
          str = storage.get(key)
        }
      } else {
        storage.remove(key)
      }

    }
    return str
  }
}

//替换图片
const replaceInfo = str => {
  let newStr = '',
    type = false
  //不是字符串转换成字符串
  if (typeof str !== 'string') {
    str = JSON.stringify(str)
    type = true
  }

  str = str.replace(/"\/?images/g, `"${baseURL}/images`)
  newStr = str.replace(/'\/?images/g, `'${baseURL}/images`)

  //转换为原有对象
  if (type) {
    newStr = JSON.parse(newStr)
  }
  return newStr
}

export { replaceInfo, handleCache }